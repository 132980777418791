body {
    color: #546174 !important
}

.main-bg {
    min-height: 100vh;
    background-image: url('./assets/image/bg.jpeg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-fluid {
    width: 100%;
}

.main-bg:before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 100vh;
    z-index: 0;
    background: inherit;
    filter: blur(5px);
}
